<template>
    <div>
        <!-- Page Title Section -->
        <section class="page-title">
            <div class="pattern-layer-one" :style="{backgroundImage: 'url('+bgPattern5+')'}"></div>
            <div class="pattern-layer-two" :style="{backgroundImage: 'url('+bgPattern6+')'}"></div>
            <div class="icon-layer-one" :style="{backgroundImage: 'url('+icon5+')'}"></div>
            <div class="icon-layer-two" :style="{backgroundImage: 'url('+icon5+')'}"></div>
            <div class="icon-layer-three" :style="{backgroundImage: 'url('+icon6+')'}"></div>
            <div class="icon-layer-four" :style="{backgroundImage: 'url('+iconPattern1+')'}"></div>
            <div class="icon-layer-five" :style="{backgroundImage: 'url('+iconPattern2+')'}"></div>
            <div class="auto-container">
                <h1>BAY HEAD, NJ 08742</h1>
                <ul class="page-breadcrumb">
                    <li>
                        <router-link :to="{name: 'Home'}">home</router-link>
                    </li>
                    <li>Real Estate</li>
                </ul>
            </div>
        </section>
        <!-- End Page Title Section -->

        <!-- Shop Detail Section -->
        <div class="shop-detail-section" style="margin-bottom: 150px">
            <div class="auto-container">
                <div class="shop-page">
                    <!--Product Details Section-->
                    <div class="product-details">
                        <!--Basic Details-->
                        <div class="basic-details">
                            <div class="row clearfix">

                                <div class="image-column col-lg-6 col-md-12 col-sm-12">
                                    <div class="carousel-outer">

                                        <ul class="image-carousel owl-carousel owl-theme">
                                            <li>
                                                <a :href="getImgLink('.tmp-img')" class="lightbox-image">
                                                    <img src="../../../assets/images/estates/3/TE_jfjdfbjdfjk.jpg" alt="" class="tmp-img">
                                                </a>
                                            </li>
                                            <li>
                                                <a :href="getImgLink('.tmp-img-2')" class="lightbox-image">
                                                    <img src="../../../assets/images/estates/3/TE_jfjdfbjdfjk_1.jpg" alt="" class="tmp-img-2">
                                                </a>
                                            </li>
                                            <li>
                                                <a :href="getImgLink('.tmp-img-3')" class="lightbox-image">
                                                    <img src="../../../assets/images/estates/3/TE_jfjdfbjdfjk_2.jpg" alt="" class="tmp-img-3">
                                                </a>
                                            </li>
                                            <li>
                                                <a :href="getImgLink('.tmp-img-4')" class="lightbox-image">
                                                    <img src="../../../assets/images/estates/3/TE_jfjdfbjdfjk_3.jpg" alt="" class="tmp-img-4">
                                                </a>
                                            </li>
                                            <li>
                                                <a :href="getImgLink('.tmp-img-5')" class="lightbox-image">
                                                    <img src="../../../assets/images/estates/3/TE_jfjdfbjdfjk_4.jpg" alt="" class="tmp-img-5">
                                                </a>
                                            </li>
                                        </ul>

                                        <ul class="thumbs-carousel owl-carousel owl-theme">
                                            <li><img src="../../../assets/images/estates/3/TE_jfjdfbjdfjk.jpg" alt=""></li>
                                            <li><img src="../../../assets/images/estates/3/TE_jfjdfbjdfjk_1.jpg" alt=""></li>
                                            <li><img src="../../../assets/images/estates/3/TE_jfjdfbjdfjk_2.jpg" alt=""></li>
                                            <li><img src="../../../assets/images/estates/3/TE_jfjdfbjdfjk_3.jpg" alt=""></li>
                                            <li><img src="../../../assets/images/estates/3/TE_jfjdfbjdfjk_4.jpg" alt=""></li>
                                        </ul>

                                    </div>

                                </div>

                                <!--Info Column-->
                                <div class="info-column col-lg-6 col-md-12 col-sm-12">
                                    <div class="details-header">
                                        <h3>BAY HEAD, NJ 08742</h3>
                                        <div class="rating"><span class="fa fa-star"></span> <span class="fa fa-star"></span> <span class="fa fa-star"></span> <span class="fa fa-star"></span> <span class="light fa fa-star"></span></div>
                                        <!--reviews-->
                                        <div class="reviews">
                                            <a href="javascript:void(0)">4.5</a> <a href="javascript:void(0)">(Hot Deals)</a>
                                        </div>
                                        <div class="item-price"><span>$720,330</span> $680,000</div>
                                    </div>
                                    <ul class="stock-list">
                                        <li><span>Available :</span> Yes</li>
                                        <li><span>Location :</span> Ocean County</li>
                                    </ul>
                                    <div class="text">
                                        Rare Bay Head condo opportunity. Steps to the beach. Completely remodeled. Located at the premier Bay Head address, with Theresa's South, Burke's market, Port Coffee Roasters all just steps away. An easy walk to the Bay Head train station, making this property an ideal weekend destination for New Yorkers.
                                    </div>

                                    <div class="other-options">
<!--                                        <div class="item-title">Quantity</div>-->
                                        <div class="clearfix">
                                            <div class="pull-left">
                                                <!--Btns Box-->
                                                <div class="btns-box clearfix">
                                                    <a href="mailto:sample@domain.com" type="button" class="theme-btn add-to-cart">
                                                        Make an offer <span class="icon flaticon-handshake"></span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <!--Basic Details-->
                    </div>
                </div>

                <!-- Product Info Tabs -->
                <div class="product-info-tabs">
                    <!-- Product Tabs -->
                    <div class="prod-tabs tabs-box">

                        <!--Tab Btns-->
                        <ul class="tab-btns tab-buttons clearfix">
                            <li data-tab="#prod-details" class="tab-btn active-btn">Description</li>
                            <li data-tab="#prod-info" class="tab-btn">Additional Information</li>
                        </ul>

                        <!--Tabs Container-->
                        <div class="tabs-content">

                            <!-- Tab / Active Tab -->
                            <div class="tab active-tab" id="prod-details">
                                <div class="content">
                                    <p>
                                        Rare Bay Head condo opportunity. Steps to the beach. Completely remodeled. Located at the premier Bay Head address, with Theresa's South, Burke's market, Port Coffee Roasters all just steps away. An easy walk to the Bay Head train station, making this property an ideal weekend destination for New Yorkers.
                                    </p>
                                </div>
                            </div>

                            <!-- Tab -->
                            <div class="tab" id="prod-info">
                                <div class="content">
                                    <div class="row">
                                        <div class="col-12 col-lg-6">
                                            <table class="table table-striped">
                                                <tbody>
                                                <tr>
                                                    <td>Listing ID:</td>
                                                    <td><strong>527263</strong></td>
                                                </tr>
                                                <tr>
                                                    <td>Bedrooms:</td>
                                                    <td>1</td>
                                                </tr>
                                                <tr>
                                                    <td>Bathrooms:</td>
                                                    <td>1</td>
                                                </tr>
                                                <tr>
                                                    <td>Type:</td>
                                                    <td>Residential</td>
                                                </tr>
                                                <tr>
                                                    <td>Subtype:</td>
                                                    <td>Condo<br></td>
                                                </tr>
                                                <tr>
                                                    <td>Lot Size:</td>
                                                    <td>0.029</td>
                                                </tr>
                                                <tr>
                                                    <td>Sq. Feet:</td>
                                                    <td>728</td>
                                                </tr>
                                                <tr>
                                                    <td>Year Built:</td>
                                                    <td>1970</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="col-12 col-lg-6">
                                            <p>Amenities</p>
                                            <ul>
                                                <li>Central Air</li>
                                                <li>Cable TV</li>
                                                <li>Central Heat</li>
                                                <li>Corner Lot</li>
                                                <li>Courtyard</li>
                                                <li>Dishwasher</li>
                                                <li>Finished Basement</li>
                                                <li>Full Basement</li>
                                                <li>Garden Area</li>
                                                <li>Granite Countertops</li>
                                                <li>Hardwood Floors</li>
                                                <li>Homeowner's Assoc.</li>
                                                <li>Laundry Room</li>
                                                <li>Living Room</li>
                                                <li>Microwave</li>
                                                <li>Porch</li>
                                                <li>Private Backyard</li>
                                                <li>Refrigerator</li>
                                                <li>Washer/Dryer</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <!-- End Shop Detail Section -->
    </div>
</template>

<script>
import icon1 from "../../../assets/images/icons/icon-1.png";
import icon2 from "../../../assets/images/icons/icon-2.png";
import icon3 from "../../../assets/images/icons/icon-3.png";
import icon4 from "../../../assets/images/icons/icon-4.png";
import bgPattern5 from "../../../assets/images/background/pattern-5.png";
import bgPattern6 from "../../../assets/images/background/pattern-6.png";
import icon5 from "../../../assets/images/icons/icon-5.png";
import icon6 from "../../../assets/images/icons/icon-6.png";
import iconPattern1 from "../../../assets/images/icons/pattern-1.png";
import iconPattern2 from "../../../assets/images/icons/pattern-2.png";

export default {
    name: "EstateDetail1",
    data(){
        return {
            icon1, icon2, icon3, icon4,
            bgPattern5, bgPattern6,
            icon5, icon6, iconPattern1,
            iconPattern2,
        }
    },
    methods: {
      getImgLink(elem){
          return $(elem).attr('src')
      }
    },
    mounted() {
        if ($('.shop-page .image-carousel').length && $('.shop-page .thumbs-carousel').length) {

            var $sync1 = $(".shop-page .image-carousel"),
                $sync2 = $(".shop-page .thumbs-carousel"),
                flag = false,
                duration = 500;

            $sync1
                .owlCarousel({
                    loop:true,
                    items: 1,
                    margin: 0,
                    nav: false,
                    navText: [ '<span class="icon fa fa-angle-left"></span>', '<span class="icon fa fa-angle-right"></span>' ],
                    dots: false,
                    autoplay: true,
                    autoplayTimeout: 5000
                })
                .on('changed.owl.carousel', function (e) {
                    if (!flag) {
                        flag = false;
                        $sync2.trigger('to.owl.carousel', [e.item.index, duration, true]);
                        flag = false;
                    }
                });

            $sync2
                .owlCarousel({
                    loop:true,
                    margin: 20,
                    items: 1,
                    nav: true,
                    navText: [ '<span class="icon fa fa-angle-left"></span>', '<span class="icon fa fa-angle-right"></span>' ],
                    dots: false,
                    center: false,
                    autoplay: true,
                    autoplayTimeout: 5000,
                    responsive: {
                        0:{
                            items:2,
                            autoWidth: false
                        },
                        400:{
                            items:3,
                            autoWidth: false
                        },
                        600:{
                            items:3,
                            autoWidth: false
                        },
                        900:{
                            items:3,
                            autoWidth: false
                        },
                        1000:{
                            items:3,
                            autoWidth: false
                        }
                    },
                })

                .on('click', '.owl-item', function () {
                    $sync1.trigger('to.owl.carousel', [$(this).index(), duration, true]);
                })
                .on('changed.owl.carousel', function (e) {
                    if (!flag) {
                        flag = true;
                        $sync1.trigger('to.owl.carousel', [e.item.index, duration, true]);
                        flag = false;
                    }
                });

        }
        if($('.lightbox-image').length) {
            $('.lightbox-image').fancybox({
                openEffect  : 'fade',
                closeEffect : 'fade',
                helpers : {
                    media : {}
                }
            });
        }
        if($('.tabs-box').length){
            $('.tabs-box .tab-buttons .tab-btn').on('click', function(e) {
                e.preventDefault();
                var target = $($(this).attr('data-tab'));

                if ($(target).is(':visible')){
                    return false;
                }else{
                    target.parents('.tabs-box').find('.tab-buttons').find('.tab-btn').removeClass('active-btn');
                    $(this).addClass('active-btn');
                    target.parents('.tabs-box').find('.tabs-content').find('.tab').fadeOut(0);
                    target.parents('.tabs-box').find('.tabs-content').find('.tab').removeClass('active-tab');
                    $(target).fadeIn(300);
                    $(target).addClass('active-tab');
                }
            });
        }
    }
}
</script>

<style scoped>

</style>